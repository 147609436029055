































































































































































































































































import Ueditor from "@/components/Ueditor/index.vue";
import { Component, Ref, Vue } from "vue-property-decorator";
import api from "@/api";
import {
  AttachmentHostType,
  DataDictionaryDtoPagedResultDto,
  FundModifyApplyCreateOrUpdateDto,
} from "@/api/appService";
import { ElForm } from "element-ui/types/form";
import AbSelect from "@/components/AbSelect/index.vue";
import MultipleUploadFile from "@/components/MultipleUploadFile/index.vue";
import SimpleUploadImage from "@/components/SimpleUploadImage/index.vue";
import MultipleUploadImage from "@/components/MultipleUploadImage/index.vue";

@Component({
  components: {
    AbSelect,
    Ueditor,
    MultipleUploadFile,
    SimpleUploadImage,
    MultipleUploadImage,
  },
})
export default class EditFundMaintain extends Vue {
  @Ref() readonly dataForm!: ElForm;
  dataId!: number;
  form: FundModifyApplyCreateOrUpdateDto = {
    id: 0,
    usageRange: undefined,
    canAnonymous: true,
  };
  typeList: any = [];
  childList: any = [];
  honorTypeList: any = [];
  fundRange: any = [];
  sexList: any = [];
  checkList: any = [];
  sex = "";
  yesOrNoList = [
    {
      key: "是",
      value: true,
    },
    {
      key: "否",
      value: false,
    },
  ];

  loading = true;

  get hostTypeFile() {
    return AttachmentHostType.Fund;
  }

  created() {
    if (this.$route.params.id) {
      this.dataId = Number(this.$route.params.id);
      api.fund.get({ id: this.dataId }).then((res) => {
        this.form = { ...res };
        this.form.id = 0;
        this.form.managementFeePercentage =
          (this.form.managementFeePercentage || 0) * 100;
        if (this.form.usageRange) {
          this.checkList = this.form.usageRange?.split(",");
        }
        this.loading = false;
      });
    }

    this.fetchEnumType();
    this.fetchDataDictionary();
  }

  async fetchEnumType() {
    await api.enumService
      .getValues({
        typeName: "FundType",
      })
      .then((res) => {
        this.typeList = res;
      });
  }

  async fetchDataDictionary() {
    await api.dataDictionary
      .getDataDictionaryListByKey({ key: "HonorType", maxResultCount: 1000 })
      .then((res: DataDictionaryDtoPagedResultDto) => {
        this.honorTypeList = res.items;
      });
    await api.dataDictionary
      .getDataDictionaryListByKey({ key: "FundRange", maxResultCount: 1000 })
      .then((res: DataDictionaryDtoPagedResultDto) => {
        this.fundRange = res.items;
      });
    await api.dataDictionary
      .getDataDictionaryListByKey({ key: "Sex", maxResultCount: 1000 })
      .then((res: DataDictionaryDtoPagedResultDto) => {
        this.sexList = res.items;
      });

    await api.dataDictionary
      .getDataDictionaryListByKey({ key: "FundCategory", maxResultCount: 1000 })
      .then((res: DataDictionaryDtoPagedResultDto) => {
        this.childList = res.items;
      });
  }
  async save() {
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        if (this.checkList) {
          this.form.usageRange = this.checkList.join(",");
        }
        this.form.fundID = this.dataId;
        this.form.managementFeePercentage =
          (this.form.managementFeePercentage ?? 0) / 100;
        await api.fundModifyApply.create({
          body: this.form,
        });
        this.$router.back();
        this.$message.success("操作成功");
      } else {
        this.$message.error("请检查表单");
      }
    });
  }

  cancel() {
    this.$router.back();
    // (this.$refs.dataForm as any).resetFields();
    // this.$emit("input", false);
  }

  roleRule = {
    name: [
      {
        required: true,
        message: "基金名称必填",
        trigger: "blur",
      },
    ],
    type: [
      {
        required: true,
        message: "基金类型必填",
        trigger: "blur",
      },
    ],
    foundDate: [
      {
        required: true,
        message: "成立日期必填",
        trigger: "blur",
      },
    ],
    founderName: [
      {
        required: true,
        message: "发起单位/个人必填",
        trigger: "blur",
      },
    ],
    intialCaptial: [
      {
        required: true,
        message: "起设资金金额必填",
        trigger: "blur",
      },
    ],
    mobileTelephone: [
      {
        required: true,
        message: "手机号码必填",
        trigger: "blur",
      },
    ],
    email: [
      {
        message: "请填写正确的邮箱",
        type: "email",
        trigger: "blur",
      },
    ],
  };
}
